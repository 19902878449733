<template>
  <ion-page class="ion-page" main>
    <ion-header>
      <ion-card color="white" class="active-conversation-header">
        <div
          class="avatar current-patient-avatar"
          v-if="currentPatient.account.firstName && currentPatient.account.lastName"
        >
          {{
            currentPatient.account.firstName.charAt(0) + currentPatient.account.lastName.charAt(0)
          }}
        </div>
        <h5 v-if="currentPatient.account.firstName && currentPatient.account.lastName">
          {{ currentPatient.account.lastName + ", " + currentPatient.account.firstName }}
        </h5>
        <div class="active-conversation-right-icons">
          <IconMoon
            v-if="currentPatient.account.doNotDisturb"
            class="icon-blue icon-small"
            v-tooltip.right="'Patient is currently in &quot;Do Not Disturb&quot;'"
          />
          <IconChatBubbleDisabled
            v-if="currentPatient.account.activeConversation"
            class="icon-blue icon-small"
            v-tooltip.right="
              'This patient is currently in an active conversation.<br>No new conversation can be started until the <br>current conversation is complete.'
            "
          />
          <button
            type="button"
            v-tooltip.top="'Go to Patient Overview'"
            @click="goToPatientGeneralSettings()"
            class="prime-button button-primary button-solo"
          >
            <IconBlocksMenu class="prime-main-menu-icon" />
          </button>
        </div>
      </ion-card>
      <ion-grid v-if="showMessageFilters" class="active-conversation-subheader-container">
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-segment
              @ionChange="changeChannel($event)"
              :value="msgChannel"
              mode="ios"
              class="prime-channel-tabs-container"
            >
              <ion-segment-button
                layout="icon-start"
                value="sms"
                class="prime-channel-tab"
                mode="ios"
              >
                <span
                  :class="{
                    'needs-response-badge': needsResponseTab == 'sms'
                  }"
                ></span>
                <IconChatBubble class="icon-small" />
                Text Message
              </ion-segment-button>
              <ion-segment-button layout="icon-start" value="zeus" mode="ios">
                <span
                  :class="{
                    'needs-response-badge': needsResponseTab == 'zeus'
                  }"
                ></span>
                <IconLock class="icon-small" />
                Secure Chat
              </ion-segment-button>
              <div class="prime-channel-tab-separator"></div>
              <ion-segment-button value="all" mode="ios" class="prime-channel-tab-all">
                All
              </ion-segment-button>
            </ion-segment>
          </ion-col>
          <ion-col size="auto">
            <MessageOptions
              v-bind="{
                interactionId
              }"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-header>

    <div
      id="message-panel-content"
      class="message-panel-content column-stretch-body"
      v-chat-scroll="{
        always: true,
        smooth: false,
        scrollonremoved: true
      }"
      ref="messagePanel"
    >
      <div class="messages-wrapper full-height">
        <ChatBubble
          v-for="message in filteredMessages"
          :key="message.id"
          v-bind="{
            message
          }"
        />
        <div class="text-muted full-width text-center" v-if="status == 'closed'">
          <div v-if="status == 'closed'">
            <small>{{ pageCopy.notifications.convoClosed }}</small>
          </div>
        </div>
        <div
          class="text-muted full-width text-center"
          v-if="initiator == 'system' && status == 'active'"
        >
          <div>
            <small>{{ pageCopy.notifications.systemMessage }}</small>
          </div>
        </div>
        <div class="text-muted full-width text-center" v-if="status == 'initiated'">
          <div>
            <small>{{ pageCopy.notifications.initiatedMessage }}</small>
          </div>
        </div>
        <div
          class="text-muted full-width full-height display-flex valign"
          v-if="filteredMessages.length == 0"
        >
          <div class="text-center full-width">
            There are no messages to display at this time
          </div>
        </div>
      </div>
    </div>
    <MessagesCopyToClipboard :messages="filteredMessages" />
    <ion-row class="margin-top-ten pad-lr-twenty" justify-content-end>
      <ion-col class="text-center">
        <button class="prime-button button-secondary full-width" @click="copyTranscript()">
          <IconCopy slot="start" class="icon-small icon-dark-grey margin-right-five" />
          Copy Transcript
        </button>
      </ion-col>
      <ion-col v-if="$can(I.SEND_TRANSCRIPT_TO_EHR)" class="text-center margin-left-ten">
        <button class="prime-button button-secondary full-width" @click="sendTranscript()">
          <IconSend slot="start" class="icon-small icon-dark-grey margin-right-five" />
          Send Transcript to EHR
        </button>
      </ion-col>
      <ion-col v-else class="text-center margin-left-ten">
        <button
          class="prime-button disabled button-secondary full-width"
          v-tooltip.top-start="{
            content: $orgMissingPermissions('The Sending Transcript to EHR feature')
          }"
        >
          <IconSend slot="start" class="icon-small icon-dark-grey margin-right-five" />
          Send Transcript to EHR
        </button>
      </ion-col>
    </ion-row>
    <ion-footer class="column-stretch-footer prime-conv-message-entry-container" mode="ios">
      <ion-toolbar>
        <MessageInput
          v-if="showMessageInput"
          v-bind="{
            interactionId,
            beneficiaryId,
            accountId,
            copy: pageCopy.messageInput,
            msgChannel
          }"
        />
        <MessageControls
          v-if="showMessageControls"
          v-bind="{
            messageTitle: subject,
            interactionId,
            initiator,
            status,
            msgChannel
          }"
        />
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import MessageControls from "@/components/Chat/MessageControls";
import MessageInput from "@/components/Chat/MessageInput";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";
import store from "@/store";

import MessageOptions from "@/components/Chat/MessageOptions";

import IconBlocksMenu from "@/components/Global/Icons/IconBlocksMenu";
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import IconChatBubbleDisabled from "@/components/Global/Icons/IconChatBubbleDisabled";
import IconCopy from "@/components/Global/Icons/IconCopy";
import IconLock from "@/components/Global/Icons/IconLock";
import IconMoon from "@/components/Global/Icons/IconMoon";
import IconSend from "@/components/Global/Icons/IconSend";
import router from "@/router";
import { addIcons } from "ionicons";
import { call, lock } from "ionicons/icons";
import MessagesCopyToClipboard from "./MessagesCopyToClipboard";

addIcons({
  "md-lock": lock.md,
  "md-phone": call.md
});

export default {
  name: "MessagePanelSingleInteraction",
  components: {
    ChatBubble: () => import("@/components/Chat/ChatBubble"),
    MessageInput,
    MessageControls,
    MessageOptions,
    IconChatBubble,
    IconLock,
    IconMoon,
    IconChatBubbleDisabled,
    MessagesCopyToClipboard,
    IconCopy,
    IconSend,
    IconBlocksMenu
  },
  data() {
    return {
      msgChannel: this.convoChannel,
      pageCopy: this.$languagePack.messagePanel,
      interactionEvent: document.config.events.interaction,
      beneficiaryId: this.currentPatient.beneficiary.id,
      subject: undefined,
      accountId: this.currentPatient.beneficiary.accountId,
      status: "active",
      initiator: "conversation",
      messages: []
    };
  },
  computed: {
    subscription() {
      return { type: "interaction", data: this.interactionId };
    },
    dbuggaMode() {
      return store.getters["dbugga/dbuggaMode"];
    },
    filteredMessages() {
      const sortedMessage = [...this.messages].sort((a, b) => a.id - b.id);
      const filteredMessages = sortedMessage.filter(
        message => message.channel === this.msgChannel || this.msgChannel == "all"
      );
      return filteredMessages;
    },
    showMessageInput() {
      return (
        this.status == "active" && this.initiator == "conversation" && this.msgChannel != "all"
      );
    },
    showMessageControls() {
      return this.status == "active" || this.status == "initiated";
    },
    needsResponseTab() {
      let lastMsg = this.messages[this.messages.length - 1];
      if (lastMsg) {
        if (
          this.initiator === "conversation" &&
          (lastMsg.direction === "incoming" ||
            (lastMsg.direction === "outgoing" && lastMsg.source === "system"))
        ) {
          return lastMsg.channel;
        }
      }
      return "NORESPONSENEEDED";
    }
  },
  props: {
    title: String,
    interactionId: Number,
    currentPatient: Object,
    convoChannel: String,
    showMessageFilters: Boolean
  },
  methods: {
    goToPatientGeneralSettings() {
      router.push(`/patient-settings/general-info/${this.beneficiaryId}`);
      this.$ionic.modalController.dismiss();
    },
    copyTranscript() {
      EventBus.$emit("copyMessagesToClipboard");
    },
    sendTranscript() {
      const payload = {
        account_id: this.accountId,
        payload: {
          beneficiary_id: this.beneficiaryId,
          interaction_id: this.interactionId
        },
        subject: this.title
      };

      const method = "post";
      const path = document.config.transcriptToEHR + "send_transcript_to_ehr";

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success!",
              message: "The transcript has been sent.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not send transcript. Please try again later.",
              message: error,
              duration: 10000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getMessages: async function() {
      if (this.interactionId) {
        // TODO use fhir endpoint to get messages by interaction id
        const method = "get";
        const path = document.config.messageApi + this.interactionId + "/messages";
        this.loading = true;
        httpSend({ path, method, authToken: true })
          .then(result => {
            this.status = result.data.status;
            this.updateMessages(result.data.messages);
            this.loading = false;
          })
          .catch(() => {
            this.$ionic.toastController
              .create({
                header: "Error",
                message: "Could not get conversation. Please try again later.",
                duration: 5000,
                position: "top"
              })
              .then(m => m.present());
            this.loading = false;
          });
      }
    },
    updateMessages(messages) {
      // concat arrays and reduce to remove duplicates
      this.messages = [...this.messages, ...messages].reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    },
    startInterval() {
      this.myInterval = setInterval(() => {
        store.dispatch("chatProfessional/updateMessages", this.interactionId);
      }, 10000);
    },
    changeChannel(event) {
      this.msgChannel = event.target.value;
    }
  },
  async created() {
    await this.getMessages();
    store.dispatch("websocket/subscribe", this.subscription);

    EventBus.$on("startSecureChat", () => {
      this.msgChannel = "zeus";
    });
  },
  beforeDestroy: function() {
    store.dispatch("websocket/unsubscribe", this.subscription);
    if (this.myInterval) {
      clearInterval(this.myInterval);
    }
  }
};
</script>

<style scoped>
.active-conversation-subheader-container {
  padding: 0 20px 8px 20px;
}
.prime-channel-tabs-container {
  background: var(--color-secondary-background);
  border-radius: 0 0 20px 20px;
  box-shadow: 0px -25px 0px 0px var(--color-secondary-background);
}
.prime-channel-tabs-container ion-segment-button {
  height: 36px;
  border-radius: 20px;
  margin: 5px;
  border: none;
  font-weight: 700;
  font-size: 12px;
  fill: var(--ion-color-primary);
  transition: background 0.2s ease-in-out;
  --indicator-color-checked: transparent;
}
.prime-channel-tabs-container ion-segment-button svg {
  margin-right: 5px;
}
.prime-channel-tabs-container ion-segment-button.segment-button-checked {
  box-shadow: var(--prime-button-shadow);
}
.prime-channel-tabs-container ion-segment-button.segment-button-checked svg {
  fill: #ffffff;
}

.prime-channel-tabs-container ion-segment-button .needs-response-badge {
  content: "";
  background: var(--ion-color-primary);
  border-radius: 10px;
  height: 10px;
  width: 10px;
  margin-right: 5px;
}
.prime-channel-tabs-container ion-segment-button.segment-button-checked .needs-response-badge {
  background: var(--ion-color-white);
}

.prime-channel-tabs-container ion-segment-button.prime-channel-tab-all {
  max-width: 60px;
}
.prime-channel-tabs-container .prime-channel-tab-separator {
  content: "";
  display: block;
  width: 2px;
  height: 30px;
  margin: 8px 5px;
  border-radius: 4px;
  background: var(--ion-color-primary);
  opacity: 10%;
}

.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}

.column-stretch-footer {
  flex: 0 0 auto;
}

ion-card ion-card {
  padding: 0;
  border-radius: 6px !important;
  margin-left: -10px;
  margin-right: -10px;
}

ion-card ion-card:first-child {
  margin-top: 5px;
}

ion-card-content.prime-minimal-list {
  padding: 0 0 0 10px;
  margin-right: -10px;
}

.prime-patient-view ion-row.prime-full-height-row {
  height: calc(100vh - 165px) !important;
}

.prime-patient-view ion-row ion-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.prime-patient-view ion-col ion-card {
  margin-bottom: 0;
}

.prime-patient-view ion-col ion-card:last-child {
  margin-bottom: 0;
}

.prime-patient-view ion-card {
  flex-shrink: 0;
}

.prime-patient-view ion-card.scroll-shrink {
  flex-shrink: 1;
  flex-grow: 1;
}

.prime-patient-view ion-card-header {
  padding: 10px 10px 10px 20px;
}

.prime-internal-scoll-card ion-card-content {
  overflow: auto;
}

.prime-internal-scoll-card {
  display: flex;
  flex-direction: column;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.prime-patient-data-feed-card ion-card ion-card-title {
  font-size: 16px;
  font-weight: 600;
}

.prime-patient-data-feed-card ion-card ion-card-subtitle {
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-color-medium) !important;
  margin-top: 0;
}

.prime-patient-data-feed-card ion-card ion-card-header {
  padding-bottom: 0;
}

/* Right-Hand Accordion */
.prime-rh-accordion ion-card-header {
  display: flex;
  cursor: pointer;
}

.prime-rh-accordion ion-card-title {
  flex-grow: 1;
}

.prime-rh-accordion ion-card-header .ion-card-toggle-controls {
  font-size: 20px;
  margin-bottom: -5px;
  color: var(--ion-color-medium);
}

.prime-rh-accordion ion-card-header .ion-card-toggle-controls ion-icon.closed {
  margin-right: -20px;
}

/* messages column */

.messages-col-inner {
  padding: 0 40px;
}

.message-panel-content {
  padding-top: 8px;
}
.messages-wrapper {
  padding: 10px 20px;
}

.message-text-input {
  width: 100%;
  background-color: transparent;
  outline: none;
}

.message-active-badge {
  background-color: var(--ion-color-primary);
  height: 8px;
  width: 8px;
}

@media screen and (max-width: 500px) {
  .sc-ion-card-md-h {
    margin: 0px !important;
    margin-top: 10px !important;
  }
}

/* end messages column */

.prime-conv-message-entry-container {
  padding: 0px 10px 20px;
}
.footer-ios ion-toolbar:first-child {
  --border-width: 0;
}

.active-conversation-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  margin-bottom: 5px !important;
  z-index: 100;
}
.active-conversation-header .avatar {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  flex-grow: 0;
}
.active-conversation-header h5 {
  margin: 0;
  flex-grow: 1;
}
.active-conversation-header .active-conversation-right-icons {
  flex-grow: 0;
  display: flex;
}

.active-conversation-title-group h6 {
  margin: 0 !important;
}

.active-conversation-options-group {
  width: 25px;
  text-align: right;
  margin-right: 20px;
  margin-left: 15px;
}
</style>
