<template>
  <div
    v-if="$can(I.MODIFY_PATIENT_DATA)"
    @click="setUrgency($event)"
    class="active-conversation-options-group"
    v-tooltip.top="'Mark conversation as ' + (isUrgent ? 'normal' : 'high') + ' priority'"
  >
    <IconFlag :class="{ 'is-urgent': isUrgent }" class="cursor-pointer icon-grey" />
  </div>
  <div
    v-else
    class="active-conversation-options-group"
    v-tooltip.top="'Conversation is ' + (isUrgent ? 'normal' : 'high') + ' priority'"
  >
    <IconFlag :class="{ 'is-urgent': isUrgent }" class="cursor-pointer icon-grey" />
  </div>
</template>

<script>
import IconFlag from "@/components/Global/Icons/IconFlag";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";

export default {
  name: "Msgoptions",
  components: {
    IconFlag
  },
  data() {
    return {
      //messageTitle: undefined,
      isUrgent: false,
      interactionObj: undefined
      // errors: [],
      // isLoading: false
    };
  },
  props: {
    interactionId: Number,
    msgChannel: String
  },
  mounted() {
    // this.getCurrentInteraction();
  },
  methods: {
    closePopover() {
      this.$ionic.popoverController.dismiss();
    },
    getCurrentInteraction: function() {
      // TODO create a new endpoint for getting a conversation in fhir api
      const method = "get";
      const path = document.config.messageApi + this.interactionId;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.interactionObj = result.data;
          if (result.data.urgency == "emergency") {
            this.isUrgent = true;
          } else {
            this.isUrgent = false;
          }
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not get current interaction. Please try again later.",
              message: error,
              duration: 10000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    setUrgency: function() {
      // TODO create a new endpoint for setting urgency in fhir api
      this.isUrgent = !this.isUrgent;
      const payload = this.interactionObj;
      payload.urgency = this.isUrgent ? "emergency" : "medium";

      const method = "put";
      const path = document.config.messageApi;

      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          EventBus.$emit("conversationUpdated", this.interactionId, this.msgChannel);
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not set urgency. Please try again later.",
              message: error,
              duration: 10000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    }
  }
};
</script>

<style scoped>
.is-urgent {
  fill: var(--ion-color-danger);
}
.active-conversation-options-group-toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
}
.active-conversation-options-group-toggle span {
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
}
</style>
