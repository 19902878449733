<template>
  <ion-row class="margin-top-ten button-group pad-lr-ten" justify-content-end>
    <ion-col class="text-center pad-right-ten">
      <button class="prime-button button-secondary full-width" @click="showActions($event)">
        <IconEllipsisHorizontal class="icon-small" />
        More Options
      </button>
    </ion-col>
    <ion-col class="text-center">
      <button
        v-if="$can(I.SEND_MESSAGE)"
        class="prime-button button-secondary full-width"
        @click="showQuickReplyModal"
      >
        <IconPaperPlane class="modal-header-icon" slot="start" name="md-paper-plane-outline" />
        Quick Reply
      </button>
      <button
        v-else
        class="prime-button disabled button-secondary full-width"
        v-tooltip.top-start="{ content: $orgMissingPermissions('The Conversations feature') }"
      >
        <IconPaperPlane class="modal-header-icon" slot="start" name="md-paper-plane-outline" />
        Quick Reply
      </button>
    </ion-col>
  </ion-row>
</template>
<script>
import PopoverConversationOptions from "@/components/Chat/PopoverConversationOptions";
import IconEllipsisHorizontal from "@/components/Global/Icons/IconEllipsisHorizontal.vue";
import { send as httpSend } from "@/services/Api";
import IconPaperPlane from "../Global/Icons/IconPaperPlane.vue";
import QuickReplyOptions from "./QuickReplyOptions";

import { addIcons } from "ionicons";
import { link } from "ionicons/icons";

addIcons({
  "md-link": link.md
});

export default {
  name: "MessageControls",
  props: {
    interactionId: Number,
    messageTitle: String,
    initiator: String,
    status: String,
    msgChannel: String,
    beneficiaryId: Number,
    urgency: String,
    filteredMessages: Array,
    accountId: Number,
    programId: Number
  },
  components: {
    IconEllipsisHorizontal,
    IconPaperPlane
  },
  computed: {
    isSecureChat() {
      return this.msgChannel === "zeus";
    },
    dbuggaMode() {
      return this.$store.getters["dbugga/dbuggaMode"];
    }
  },
  methods: {
    showActions(ev) {
      this.$ionic.popoverController
        .create({
          component: PopoverConversationOptions,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            data: {
              messageTitle: this.messageTitle,
              interactionId: this.interactionId,
              initiator: this.initiator,
              msgChannel: this.msgChannel,
              status: this.status,
              beneficiaryId: this.beneficiaryId,
              urgency: this.urgency,
              filteredMessages: this.filteredMessages,
              accountId: this.accountId,
              professionalId: this.professionalId,
              programId: this.programId
            }
          }
        })
        .then(m => m.present());
    },
    showQuickReplyModal: function() {
      this.$ionic.modalController
        .create({
          component: QuickReplyOptions,
          cssClass: "modal-double-wide",
          mode: "ios",
          componentProps: {
            data: {
              messageTitle: this.messageTitle,
              interactionId: this.interactionId,
              initiator: this.initiator,
              status: this.status
            }
          }
        })
        .then(m => m.present());
    },
    resendSCLink: async function() {
      const method = "post";
      const path = document.config.resendZeusLink + this.interactionId + "/send";
      const payload = this.isDbugga && this.dbuggaMode ? "dbugga" : "twilio";
      const headers = {
        "Content-Type": "text/plain"
      };

      try {
        await httpSend({ path, method, payload, authToken: true, headers });

        this.$ionic.toastController
          .create({
            header: "Success!",
            message: "A new link to Secure Chat has been sent",
            duration: 4000,
            color: "primary",
            mode: "ios"
          })
          .then(m => m.present());
      } catch (error) {
        this.$ionic.toastController
          .create({
            header: "Error!",
            message: "There was a problem resending the secure chat link. Please try again later.",
            duration: 4000,
            position: "top"
          })
          .then(m => m.present());

        khanSolo.log(error);
      }
    }
  }
};
</script>

<style scoped>
.disabled {
  color: var(--ion-color-medium) !important;
}
.disabled svg {
  fill: var(--ion-color-medium) !important;
}
</style>
