var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ion-page',{staticClass:"ion-page",attrs:{"main":""}},[_c('ion-header',[_c('ion-card',{staticClass:"active-conversation-header",attrs:{"color":"white"}},[(_vm.currentPatient.account.firstName && _vm.currentPatient.account.lastName)?_c('div',{staticClass:"avatar current-patient-avatar"},[_vm._v(" "+_vm._s(_vm.currentPatient.account.firstName.charAt(0) + _vm.currentPatient.account.lastName.charAt(0))+" ")]):_vm._e(),(_vm.currentPatient.account.firstName && _vm.currentPatient.account.lastName)?_c('h5',[_vm._v(" "+_vm._s(_vm.currentPatient.account.lastName + ", " + _vm.currentPatient.account.firstName)+" ")]):_vm._e(),_c('div',{staticClass:"active-conversation-right-icons"},[(_vm.currentPatient.account.doNotDisturb)?_c('IconMoon',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Patient is currently in "Do Not Disturb"'),expression:"'Patient is currently in \"Do Not Disturb\"'",modifiers:{"right":true}}],staticClass:"icon-blue icon-small"}):_vm._e(),(_vm.currentPatient.account.activeConversation)?_c('IconChatBubbleDisabled',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(
            'This patient is currently in an active conversation.<br>No new conversation can be started until the <br>current conversation is complete.'
          ),expression:"\n            'This patient is currently in an active conversation.<br>No new conversation can be started until the <br>current conversation is complete.'\n          ",modifiers:{"right":true}}],staticClass:"icon-blue icon-small"}):_vm._e(),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Go to Patient Overview'),expression:"'Go to Patient Overview'",modifiers:{"top":true}}],staticClass:"prime-button button-primary button-solo",attrs:{"type":"button"},on:{"click":function($event){return _vm.goToPatientGeneralSettings()}}},[_c('IconBlocksMenu',{staticClass:"prime-main-menu-icon"})],1)],1)]),(_vm.showMessageFilters)?_c('ion-grid',{staticClass:"active-conversation-subheader-container"},[_c('ion-row',{staticClass:"ion-align-items-center"},[_c('ion-col',[_c('ion-segment',{staticClass:"prime-channel-tabs-container",attrs:{"value":_vm.msgChannel,"mode":"ios"},on:{"ionChange":function($event){return _vm.changeChannel($event)}}},[_c('ion-segment-button',{staticClass:"prime-channel-tab",attrs:{"layout":"icon-start","value":"sms","mode":"ios"}},[_c('span',{class:{
                  'needs-response-badge': _vm.needsResponseTab == 'sms'
                }}),_c('IconChatBubble',{staticClass:"icon-small"}),_vm._v(" Text Message ")],1),_c('ion-segment-button',{attrs:{"layout":"icon-start","value":"zeus","mode":"ios"}},[_c('span',{class:{
                  'needs-response-badge': _vm.needsResponseTab == 'zeus'
                }}),_c('IconLock',{staticClass:"icon-small"}),_vm._v(" Secure Chat ")],1),_c('div',{staticClass:"prime-channel-tab-separator"}),_c('ion-segment-button',{staticClass:"prime-channel-tab-all",attrs:{"value":"all","mode":"ios"}},[_vm._v(" All ")])],1)],1),_c('ion-col',{attrs:{"size":"auto"}},[_c('MessageOptions',_vm._b({},'MessageOptions',{
              interactionId: _vm.interactionId
            },false))],1)],1)],1):_vm._e()],1),_c('div',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll",value:({
      always: true,
      smooth: false,
      scrollonremoved: true
    }),expression:"{\n      always: true,\n      smooth: false,\n      scrollonremoved: true\n    }"}],ref:"messagePanel",staticClass:"message-panel-content column-stretch-body",attrs:{"id":"message-panel-content"}},[_c('div',{staticClass:"messages-wrapper full-height"},[_vm._l((_vm.filteredMessages),function(message){return _c('ChatBubble',_vm._b({key:message.id},'ChatBubble',{
          message
        },false))}),(_vm.status == 'closed')?_c('div',{staticClass:"text-muted full-width text-center"},[(_vm.status == 'closed')?_c('div',[_c('small',[_vm._v(_vm._s(_vm.pageCopy.notifications.convoClosed))])]):_vm._e()]):_vm._e(),(_vm.initiator == 'system' && _vm.status == 'active')?_c('div',{staticClass:"text-muted full-width text-center"},[_c('div',[_c('small',[_vm._v(_vm._s(_vm.pageCopy.notifications.systemMessage))])])]):_vm._e(),(_vm.status == 'initiated')?_c('div',{staticClass:"text-muted full-width text-center"},[_c('div',[_c('small',[_vm._v(_vm._s(_vm.pageCopy.notifications.initiatedMessage))])])]):_vm._e(),(_vm.filteredMessages.length == 0)?_c('div',{staticClass:"text-muted full-width full-height display-flex valign"},[_c('div',{staticClass:"text-center full-width"},[_vm._v(" There are no messages to display at this time ")])]):_vm._e()],2)]),_c('MessagesCopyToClipboard',{attrs:{"messages":_vm.filteredMessages}}),_c('ion-row',{staticClass:"margin-top-ten pad-lr-twenty",attrs:{"justify-content-end":""}},[_c('ion-col',{staticClass:"text-center"},[_c('button',{staticClass:"prime-button button-secondary full-width",on:{"click":function($event){return _vm.copyTranscript()}}},[_c('IconCopy',{staticClass:"icon-small icon-dark-grey margin-right-five",attrs:{"slot":"start"},slot:"start"}),_vm._v(" Copy Transcript ")],1)]),(_vm.$can(_vm.I.SEND_TRANSCRIPT_TO_EHR))?_c('ion-col',{staticClass:"text-center margin-left-ten"},[_c('button',{staticClass:"prime-button button-secondary full-width",on:{"click":function($event){return _vm.sendTranscript()}}},[_c('IconSend',{staticClass:"icon-small icon-dark-grey margin-right-five",attrs:{"slot":"start"},slot:"start"}),_vm._v(" Send Transcript to EHR ")],1)]):_c('ion-col',{staticClass:"text-center margin-left-ten"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top-start",value:({
          content: _vm.$orgMissingPermissions('The Sending Transcript to EHR feature')
        }),expression:"{\n          content: $orgMissingPermissions('The Sending Transcript to EHR feature')\n        }",modifiers:{"top-start":true}}],staticClass:"prime-button disabled button-secondary full-width"},[_c('IconSend',{staticClass:"icon-small icon-dark-grey margin-right-five",attrs:{"slot":"start"},slot:"start"}),_vm._v(" Send Transcript to EHR ")],1)])],1),_c('ion-footer',{staticClass:"column-stretch-footer prime-conv-message-entry-container",attrs:{"mode":"ios"}},[_c('ion-toolbar',[(_vm.showMessageInput)?_c('MessageInput',_vm._b({},'MessageInput',{
          interactionId: _vm.interactionId,
          beneficiaryId: _vm.beneficiaryId,
          accountId: _vm.accountId,
          copy: _vm.pageCopy.messageInput,
          msgChannel: _vm.msgChannel
        },false)):_vm._e(),(_vm.showMessageControls)?_c('MessageControls',_vm._b({},'MessageControls',{
          messageTitle: _vm.subject,
          interactionId: _vm.interactionId,
          initiator: _vm.initiator,
          status: _vm.status,
          msgChannel: _vm.msgChannel
        },false)):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }