<template>
  <div class="prime-popover prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconChatBubble slot="start" class="icon-small" />
        <h5 slot="start">End Conversation</h5>
        <ion-button
          slot="end"
          mode="ios"
          @click="dismissPopover()"
          expand="full"
          color="primary"
          fill="clear"
          ><ion-icon name="md-close"></ion-icon
        ></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div v-if="status == 'active'">
        <h6>Conversation title:</h6>
        <p class="prime-form-subtitle">
          Please provide a short, descriptive title that summarizes the topic of this conversation.
        </p>
        <input
          class="form-control"
          :class="{ 'validation-error': error }"
          v-model="messageTitle"
          :maxlength="255"
          placeholder="Set Message Title..."
        />
      </div>
      <button class="prime-button button-block margin-top-ten" @click="endConversation()">
        End Conversation
      </button>
    </ion-content>
  </div>
</template>

<script>
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";

export default {
  name: "MessageEndconvoOptions",
  components: {
    IconChatBubble
  },
  data() {
    return {
      interactionId: undefined,
      messageTitle: undefined,
      error: undefined,
      initiator: undefined,
      status: undefined,
      beneficiaryId: undefined
    };
  },
  methods: {
    async endConversation() {
      // reset error
      this.error = false;
      if (!this.messageTitle) {
        this.error = true;
      } else {
        // TODO create a new endpoint for closing a conversation in fhir api
        const method = "POST";
        const path = document.config.messageApi + this.interactionId + "/close";
        const payload = {
          title: this.messageTitle,
          interactionId: this.interactionId
        };

        try {
          await httpSend({ path, method, payload, authToken: true });
        } catch (error) {
          khanSolo.log(error);
        }
        EventBus.$emit("conversationClosed", this.interactionId, this.beneficiaryId);
        this.dismissPopover();
      }
    },
    dismissPopover() {
      this.$ionic.popoverController.dismiss();
    }
  }
};
</script>
<style scoped>
.validation-error {
  border: 2px solid rgb(198, 0, 0);
}
</style>
